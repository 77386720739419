import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import diamond from './assets/reviewsRating.png';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1200, min: 800 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 800, min: 0 },
        items: 1,
    }
};


export default function ReviewsSlide({ slideData }) {
    return (
        <Carousel responsive={responsive}
            partialVisible={true}
            renderButtonGroupOutside={true}
            arrows={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all 500ms linear"
            transitionDuration={1000}
            containerClass="reviewscarousel-container"
            dotListClass="reviewscustom-dot-list-style"
            itemClass="reviewscarousel-item-padding-40-px"
        >
            {slideData.map((singleSlide, index) => {
                const {fp_reviewsRepeaterName, fp_reviewsRepeaterImage, fp_reviewsRepeaterText} = singleSlide;

                return (
                    <section key={index} className="reviewsSlideContainer">
                        <div className="reviewsSlideRatingContainer">
                            <img src={diamond} alt="5 diamant logoer" />
                        </div>
                        <div className="reviewsSlideText" dangerouslySetInnerHTML={{__html: fp_reviewsRepeaterText}}></div>
                        <div className="reviewsSlideImageContainer">
                            <img src={fp_reviewsRepeaterImage} alt="Billede af anmelder" />
                        </div>
                        <p className="reviewsSlideName">{fp_reviewsRepeaterName}</p>
                    </section>
                )
            })}
        </Carousel>
    )
}