import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slide from './NewsSlide';
import './news.css';

export default function News({ newsData, productsData }) {
    const [news, setNews] = useState([newsData]);
    const [products, setProducts] = useState(productsData);
    let newsProducts = [];

    products.map((product) => {
        if (product.acf.bd_productsOnlyOnNews === 'true') {
            newsProducts.push(product);
        }
    })

    return (
        <section id="news">
            {news.map((data, index) => {
                const {fp_newsHeading, fp_newsSubheadingText, fp_newsCtaText} = data;

                return (
                    <section key={index} className='newsContainer'>
                        <section className="newsTextContainer">
                            <article className="newsTextWrapper">
                                <h2 className="newsHeading">{fp_newsHeading}</h2>
                                <div className="newsShorttext" dangerouslySetInnerHTML={{__html: fp_newsSubheadingText}}></div>
                                <Link to='/baggedesign'>{fp_newsCtaText}</Link>
                            </article>
                        </section>

                        <section className="newsSlideContainer">
                            <Slide slideData={newsProducts} />
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
