import React, { useState } from 'react';

import SingleproductComponent from '../components/baggeDesign/singleproduct/Singleproduct';

export default function Singleproduct({ productsData, productsSlug }) {
    const [singleproduct, setSingleproduct] = useState(productsData);
    return (
        <>
            <SingleproductComponent singleproductData={singleproduct} productsSlug={productsSlug} />
        </>
    )
}
