import React, { useState } from 'react';

import About from '../components/frontpage/about/About';
import Bybagges from '../components/frontpage/bybagges/Bybagges';
import BybaggesProds from '../components/frontpage/bybaggesProds/BybaggesProds';
import Cards from '../components/frontpage/cards/Cards';
import Landingpage from '../components/frontpage/landingpage/LandingPage';
import News from '../components/frontpage/news/News';
import Reviews from '../components/frontpage/reviews/Reviews';


export default function Home({ frontpageData, dreamsbybaggesData, productsData }) {
    const [frontpage, setFrontpage] = useState(frontpageData);
    // const [dreamsbybagges, setDreamsbybagges] = useState(dreamsbybaggesData);
    const [products, setProducts] = useState(productsData);

    return (
        <>
            <Landingpage landingpageData={frontpage} />
            <News newsData={frontpage} productsData={products} />
            <About aboutData={frontpage} />
            <Cards cardsData={frontpage} />
            <Reviews reviewsData={frontpage} />
            <Bybagges bybaggesData={frontpage} />
            <BybaggesProds bybaggesProdsData={frontpage} productsData={products} />
        </>
    )
}
