import React, { useState } from 'react';
import './contact.css';

import { FaPhone } from "react-icons/fa6";
import { IoMdPin } from "react-icons/io";

export default function Contact({ contactData }) {
    const [contactSection, setContact] = useState([contactData]);

    return (
        <section id="contact">
            {contactSection.map((data, index) => {
                const {contactHeading, contactShortText, contactHorsensHeading, contactHorsensAddress, contactHorsensImageStore, contactHorsensOpenHours, contactHedenstedHeading, contactHedenstedAddress, contactHedenstedImageStore, contactHedenstedOpenHours, contactHorsensNumber, contactHedenstedNumber} = data;
                return (
                    <section key={index} className="contactContainer">
                        <article className="contactHeadingContainer">
                            <h1 className="contactHeading">{contactHeading}</h1>
                            <div className="contactText" dangerouslySetInnerHTML={{__html: contactShortText}}></div>
                        </article>
                        <section className="contactContentContainer">

                            <section className="contactPlaceContainer">
                                <div className="contactPlaceWrapper">
                                    <div className="contactPlaceTextContainer">
                                        <div className="contactPlaceTextFlex">
                                            <h2 className="contactPlaceHeading">{contactHorsensHeading}</h2>
                                            <div className="contactPlaceAddressContainer">
                                                <IoMdPin />
                                                <p className="contactPlaceAddress">{contactHorsensAddress}</p>
                                            </div>
                                            <div className="contactPlacePhoneContainer">
                                                <FaPhone />
                                                <p className="contactPlacePhone">{contactHorsensNumber}</p>
                                            </div>
                                        </div>

                                        <div className="contactPlaceTextFlex">
                                            {contactHorsensOpenHours.map((hours, index) => {
                                                const {contactHorsensOpenHoursDay, contactHorsensOpenHoursTime} = hours;
                                                return (
                                                    <div key={index} className="contactPlaceOpenHoursWrapper">
                                                        <p className="contactPlaceOpenHoursDayTime">{contactHorsensOpenHoursDay}</p>
                                                        <p className="contactPlaceOpenHoursDayTime">{contactHorsensOpenHoursTime}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="contactPlaceImageContainer">
                                        <img src={contactHorsensImageStore} alt="Billede af butik i Horsens" />
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2239.0728250065563!2d9.843153177191242!3d55.86140288362077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c63046750ff39%3A0xf6c412342c532c02!2sHospitalsgade%209%2C%208700%20Horsens!5e0!3m2!1sda!2sdk!4v1700235552329!5m2!1sda!2sdk" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </section>




                            <section className="contactPlaceContainer">
                                <div className="contactPlaceWrapper">
                                    <div className="contactPlaceTextContainer">
                                        <div className="contactPlaceTextFlex">
                                            <h2 className="contactPlaceHeading">{contactHedenstedHeading}</h2>
                                            <div className="contactPlaceAddressContainer">
                                                <IoMdPin />
                                                <p className="contactPlaceAddress">{contactHedenstedAddress}</p>
                                            </div>
                                            <div className="contactPlacePhoneContainer">
                                                <FaPhone />
                                                <p className="contactPlacePhone">{contactHedenstedNumber}</p>
                                            </div>
                                        </div>

                                        <div className="contactPlaceTextFlex">
                                            {contactHedenstedOpenHours.map((hours, index) => {
                                                const {contactHedenstedOpenHoursDay, contactHedenstedOpenHoursTime} = hours;
                                                return (
                                                    <div key={index} className="contactPlaceOpenHoursWrapper">
                                                        <p className="contactPlaceOpenHoursDayTime">{contactHedenstedOpenHoursDay}</p>
                                                        <p className="contactPlaceOpenHoursDayTime">{contactHedenstedOpenHoursTime}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="contactPlaceImageContainer">
                                        <img src={contactHedenstedImageStore} alt="Billede af butik i Horsens" />
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2244.444165579783!2d9.692128877186745!3d55.768157290807935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c8657cd2f7bdd%3A0x142f533d4e2b803e!2sVejlevej%208%2C%208722%20Hedensted!5e0!3m2!1sda!2sdk!4v1700235797385!5m2!1sda!2sdk" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </section>

                        </section>
                    </section>
                )
            })}
        </section>
    )
}
