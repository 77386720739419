import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Categories from './pages/Categories';
import Contact from './pages/Contact';
import Home from "./pages/Home";
import Order from './pages/Order';
import Products from './pages/Products';
import SharedProductsLayout from './pages/SharedProductsLayout';
import Singleproduct from './pages/Singleproduct';

import Footer from './components/footer/Footer';
import Loading from './components/loading/Loading';
import Navbar from './components/navbar/Navbar';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

const baseURL = 'https://baggedesigncms.baggedesign.dk/wp-json/wp/v2/';

const frontpageURL = `${baseURL}frontpage`;
const contactpageURL = `${baseURL}contact`;
const baggedesignURL = `${baseURL}baggedesign`;
const reservationURL = `${baseURL}reservation`;
const dreamsbybaggesURL = `${baseURL}dreamsbybagges`;
const footerURL = `${baseURL}footer`;
const productsURL = `${baseURL}products?per_page=100`;

const fetchURLs = [frontpageURL, contactpageURL, baggedesignURL, reservationURL, dreamsbybaggesURL, footerURL, productsURL];

function App() {
    const [isLoading, setIsLoading] = useState(true);
	const [frontpage, setFrontpage] = useState(true);
	const [contactpage, setContactpage] = useState(true);
	const [baggedeisgn, setBaggedeisgn] = useState(true);
	const [reservation, setReservation] = useState(true);
	const [dreamsbybagges, setDreamsbybagges] = useState(true);
	const [footer, setFooter] = useState(true);
	const [products, setProducts] = useState(true);

    const fetchData = async () => {
		setIsLoading(true);
		const [frontpageData, contactpageData, baggedesignData, reservationData, dreamsbybaggesData, footerData, productsData] = await Promise.all(
		  	fetchURLs.map((url) => fetch(url).then((res) => res.json()))
		);
		setFrontpage(frontpageData[0].acf);
		setContactpage(contactpageData[0].acf);
		setBaggedeisgn(baggedesignData);
		setReservation(reservationData[0].acf);
		setDreamsbybagges(dreamsbybaggesData[0].acf);
		setFooter(footerData[0].acf);
		setProducts(productsData);
		setIsLoading(false);
	}

    useEffect(() => {
		fetchData();
	}, []);

    if(isLoading) {
		return (
		  <>
			<Loading loading={isLoading} color={'#FF7F50'} />
		  </>
		)
	}

    return (
        <div className="App">
            <ScrollToTop />
            <section className="navMainContainer">
				<Navbar />
			</section>
            <section className='mainContent'>
				<Routes>
					<Route path='/' element={<Home frontpageData={frontpage} dreamsbybaggesData={dreamsbybagges} productsData={products} />} />
					<Route path='/kontakt' element={<Contact contactpageData={contactpage} />} />
					<Route path='/baggedesign' element={<SharedProductsLayout />} >
						<Route index element={<Categories categoriesData={baggedeisgn} />} />
						<Route path=':productCategory' element={<Products productsData={products} categorysData={baggedeisgn} />} />
						<Route path=':productCategory/:productId' element={<Singleproduct productsData={products} />} />
					</Route>

					<Route path='/dreamsbybagges' element={<SharedProductsLayout />} >
						<Route index element={<Products productsData={products} categorysData='Dreams By Bagges' />} />
						<Route path=':productId' element={<Singleproduct productsData={products} />} />
						<Route path=':productId/bestil' element={<Order productsData={products} orderData={reservation} />} />
					</Route>
				</Routes>
			</section>
			<section className='footerMainContainer'>
				<Footer footerData={footer} contactData={contactpage} />
			</section>
        </div>
    );
}

export default App;
