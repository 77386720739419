import React, { useState } from 'react';

import ProductsComponent from '../components/baggeDesign/products/Products';

export default function Products({ productsData, categorysData }) {
    const [products, setProducts] = useState(productsData);
    return (
       <>
            <ProductsComponent productsData={products} categoryData={categorysData} />
       </> 
    )
}
