import _ from 'lodash';
import React, { useState } from 'react';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import diamond from './assets/diamond.png';
import closeIcon from './assets/spCloseIcon.png';
import './singleproduct.css';

export default function Singleproduct({ singleproductData }) {
    const [products, setProducts] = useState(singleproductData);
    const [modalImage, setModalImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const { productId } = useParams();

    let singleProduct = [];

    products.map((product) => {
        if(product.id == productId) {
            singleProduct.push(product);
        }
    })

    const toggleModal = (modalImage, currentImageIndex) => {
        setModalImage(modalImage);
        setCurrentImageIndex(currentImageIndex);
    }

    const nextSlide = (list) => {
        const length = list.length;
        const currentIndex = currentImageIndex + 1 > length - 1 ? length - 1 : currentImageIndex + 1;
        setModalImage(list[currentIndex]);
        setCurrentImageIndex(currentIndex);
    }

    const prevSlide = (list) => {
        const currentIndex = currentImageIndex - 1 < 0 ? 0 : currentImageIndex - 1;
        setModalImage(list[currentIndex]);
        setCurrentImageIndex(currentIndex);
    }

    return (
        <section id="singleProduct">
            {singleProduct.map((product) => {
                const {id, acf} = product;

                const allSellingpoints = []; 
                const getSellingPoints = (text, index) => {
                    return (
                        <div key={index} className="singleProductSellingpointWrapper">
                            <img src={diamond} alt="Diamant ikon" />
                            <p className="singleProductSellingpoint">{text}</p>
                        </div>
                    )
                }

                if(acf.bd_productsSellingpoints) {
                    acf.bd_productsSellingpoints.map((singlePoint, index) => {
                        allSellingpoints.push(getSellingPoints(singlePoint.bd_productsSellingpointsText, index));
                    })
                }

                const baggedesignBtn = () => {
                    return (
                        <div className="singleProductBtnContainerBaggedesign">
                            <p className="singleProductBtnTextBaggedesign">Ring eller kom forbi for at høre nærmere</p>
                        </div>
                    )
                }

                const dreamsByBaggeBtn = () => {
                    return (
                        <Link className="singleProductBtnContainer" to={`/dreamsbybagges/${productId}/bestil`}>
                            <span className="singleProductBtnText">Bestil smykke</span>
                        </Link>
                    )   
                }

                const soldOutBtn = () => {
                    return (
                        <div className="singleProductBtnContainer soldOut">
                            <span className="singleProductBtnText">Udsolgt</span>
                        </div>
                    )   
                }

                const displayLongText = () => {
                    return (
                        <div className="singleProductLongText" dangerouslySetInnerHTML={{__html: acf.bd_productsLongText}}></div>
                    )
                }

                const displayShortText = () => {
                    return (
                        <div className="singleProductLongText" dangerouslySetInnerHTML={{__html: acf.bd_productsShortText}}></div>
                    )
                }

                const stockStatus = () => {
                    const productStockSize = parseInt(acf.bd_productStock);
                    if(productStockSize > 4) {
                        return 'Mere end 5 på lager';
                    } else if(productStockSize < 5 && productStockSize > 0) {
                        return 'Mindre end 5 på lager';
                    } else if(productStockSize === 0) {
                        return 'Udsolgt - Kontakt Bagge Design';
                    }
                }

                return (
                    <section key={id} className="singleProductContainer">
                        <section className="singleProductImageContainer">
                            <div className="singleProductImagesContainer">
                                {acf.bd_productImages.map((image, index) => {
                                    return (
                                        <div key={index} className="singleProductImagesWrapper" onClick={() => toggleModal(image, index)}>
                                            <img src={image} alt="Product billede" />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="singleProductImageWrapper" onClick={() => toggleModal(acf.bd_productImages[0], 0)}>
                                <img src={acf.bd_productImages[0]} alt={`Billede af ${acf.bd_productsName}`} />
                            </div>
                        </section>
                        <section className="singleProductContentContainer">
                            <h2 className="singleProductName">{acf.bd_productsName}</h2>
                            <p className="singleProductBrandName">{acf.bd_productsBrand === 'dreamsbybagges' ? 'Dreams By Bagges' : 'Bagge Design'}</p>
                            {acf.bd_productsLongText === '' ? displayShortText() : displayLongText()}
                            <div className="singleProductSellingpointsContainer">
                                {allSellingpoints.length !== 0 ? allSellingpoints : ''}
                            </div>
                            <p className="singleProductPrice"><span>DKK</span> {acf.bd_productsPrice} kr.</p>
                            <p className="singleProductStockSize">{stockStatus()}</p>
                            {acf.bd_productStock === '0' ? soldOutBtn() : (acf.bd_productsBrand === 'baggedesign' ? baggedesignBtn() : dreamsByBaggeBtn())}
                        </section>
                        <section className={modalImage !== null ? 'modalContainer active' : 'modalContainer'}>
                                <div className="modalCloseContainer">
                                    <img className='closeModalIcon' src={closeIcon} alt="lukke ikon" onClick={() => toggleModal(null, 0)} />
                                </div>
                                <section className="modalWrapper">
                                    <div className="modalArrowContainer">
                                        <BiSolidLeftArrow className='modalArrow' onClick={() => prevSlide(acf.bd_productImages)} />
                                    </div>
                                    <img className="modalImage" src={modalImage} alt="Galleri billede" />
                                    <div className="modalArrowContainer">
                                        <BiSolidRightArrow className='modalArrow' onClick={() => nextSlide(acf.bd_productImages)} />
                                    </div>
                                </section>
                            </section>
                    </section>
                )
            })}
        </section>
    )
}
