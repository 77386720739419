import React, { useState } from 'react';

import OrderComponent from '../components/baggeDesign/order/Order';

export default function Order({ productsData, orderData }) {
    const [order, setOrder] = useState(productsData);
    return (
        <>
            <OrderComponent productsData={order} orderData={orderData} />
        </>
    )
}
