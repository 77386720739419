import React, { useState } from 'react';
import diamondIcon from './assets/diamond.png';
import './bybagges.css';

export default function Bybagges({ bybaggesData }) {
    const [reviews, setReviews] = useState([bybaggesData]);

    return (
        <section id="fpBybagges">
            {reviews.map((data, index) => {
                const {fp_bybaggesHeading, fp_bybaggesDescriptiveText, fp_bybaggesPointers, fp_bybaggesSellingText, fp_bybaggesShowVideo, fp_bybaggesImage, fp_bybaggesVideo} = data;
                
                const showImage = (src) => {
                    return (
                        <img src={src} alt="By Bagges billede" className="fpBybaggesMedia" />
                    )
                }
                const showVideo = (src) => {
                    return (
                        <video className="fpBybaggesMedia" playsInline loop muted autoPlay>
                            <source src={src} />
                        </video>
                    )
                }
                
                return (
                    <section key={index} className="fpBybaggesContainer">
                        <section className="fpBybaggesTextContainer">
                            <article className="fpBybaggestextWrapper">
                                <h2 className="fpBybaggesHeading">{fp_bybaggesHeading}</h2>
                                <div className="fpBybaggesText" dangerouslySetInnerHTML={{__html: fp_bybaggesDescriptiveText}}></div>
                                <div className="fpBybaggesPointersContainer">
                                    {fp_bybaggesPointers.map((singlePoint, index) => {
                                        const {fp_bybaggesPointersTekst} = singlePoint;
                                        return (
                                            <div key={index} className="fpBybaggesPointersSingleWrapper">
                                                <img src={diamondIcon} alt="Diamant ikon" />
                                                <p className="fpBybaggesPointersSingleText">{fp_bybaggesPointersTekst}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="fpBybaggesText" dangerouslySetInnerHTML={{__html: fp_bybaggesSellingText}}></div>
                            </article>
                        </section>

                        <section className="fpBybaggesMediaContainer">
                            {fp_bybaggesShowVideo ? showVideo(fp_bybaggesVideo) : showImage(fp_bybaggesImage)}
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
