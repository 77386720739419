import React, { useState } from 'react';

import Contactpage from '../components/contact/Contact';

export default function Contact({ contactpageData }) {
    const [contactpage, setContactpage] = useState(contactpageData);

    return (
        <>
            <Contactpage contactData={contactpage} />
        </>
    )
}
