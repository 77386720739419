import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './products.css';

export default function Products({ productsData, categoryData }) {
    const [products, setProducts] = useState(productsData);
    const [categories, setCategories] = useState(categoryData);
    const { productCategory } = useParams();

    let categoryOfProductsName = ''; 

    const testing = window.location.pathname
    const brandArray = testing.split('/'); 
    const brand = brandArray[1];

    let productsByCategory = [];

    if(brand === 'dreamsbybagges') {
        categoryOfProductsName = categories;
        products.map((product) => {
            if(product.acf.bd_productsBrand === brand) {  
                productsByCategory.push(product);
            }
        })
    }
    if(brand === 'baggedesign') {
        categories.map((category) => {
            const { slug, title } = category;
            if(slug === productCategory) {
                categoryOfProductsName = title.rendered;
            }
        })
        
        products.map((product) => {   
            if(product.acf.bd_productsBrand === brand) {
                if(product.acf.bd_productCategory === productCategory) {
                    if(product.acf.bd_productsOnlyOnNews !== 'true') {
                        productsByCategory.push(product);
                    }
                }
            }
        })
    }

    return (
        <section id="products">
            <section className="productsContainer">
                <h1 className="productsHeading">{categoryOfProductsName}</h1>
                <div className="productsFlexWrapper">
                    {productsByCategory.map((data) => {
                        const { id, acf } = data;

                        const renderBaggedesignBtn = () => {
                            return (
                                <Link className='productsSingleBtn' to={`/baggedesign/${acf.bd_productCategory}/${id}`}>Læs mere</Link>
                            )
                        }

                        const renderDreamsByBaggesBtn = () => {
                            return (
                                <Link className='productsSingleBtn' to={`/dreamsbybagges/${id}`}>Læs mere</Link>
                            )
                        }

                        return (
                            <section key={id} className="productsSingleContainer">
                                <div className="productsSingleImageContainer">
                                    <img src={acf.bd_productImages[0]} alt="Produkt billede" />
                                </div>
                                <article className="productsSingleTextContainer">
                                    <div className="productsSingleNamePriceContainer">
                                        <h3 className="productsSingleName">{acf.bd_productsName}</h3>
                                        <p className="productsSinglePrice">{acf.bd_productsPrice} kr.</p>
                                    </div>
                                    <div className="productsSingleShortText" dangerouslySetInnerHTML={{__html: acf.bd_productsShortText}}></div>
                                    <div className="productsSingleBtnContainer">
                                        {acf.bd_productsBrand === 'baggedesign' ? renderBaggedesignBtn() : renderDreamsByBaggesBtn()}
                                    </div>
                                </article>
                            </section>
                        )
                    })}
                </div>
            </section>
        </section>
    )
}
