import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './bybaggesProds.css';

export default function BybaggesProds({ bybaggesProdsData, productsData }) {
    const [bybaggesProds, setBybaggesProds] = useState([bybaggesProdsData]);
    const [products, setProducts] = useState(productsData);
    let dreamsByBaggesProducts = [];
    let dreamsbybaggesNewsProducts = [];

    products.map((product) => {
        if(product.acf.bd_productsBrand === 'dreamsbybagges') {
            dreamsByBaggesProducts.push(product);
        }  
    })
    
    if(dreamsByBaggesProducts.length > 4) {
        dreamsbybaggesNewsProducts = dreamsByBaggesProducts.slice(0, 4);
    } else {
        dreamsByBaggesProducts.map((product) => {
            dreamsbybaggesNewsProducts.push(product);
        })
    }

    return (
        <section id="fpBybaggesProds">
            {bybaggesProds.map((data, index) => {
                const {fp_bybaggesProdHeading, fp_bybaggesProdBtnText} = data;
                return (
                    <section key={index} className="fpBybaggesProdsContainer">
                        <h2 className="fpBybaggesProdsHeading">{fp_bybaggesProdHeading}</h2>
                        <section className="fpBybaggesProdsProductsContainer">
                            {dreamsbybaggesNewsProducts.map((prod, prodIndex) => {
                                const { id, acf} = prod;
                                return (
                                    <div key={id} className="fpBybaggesProdsSingleContainer">
                                        <div className="fpBybaggesProdsSingleImageContainer">
                                            <img src={acf.bd_productImages[0]} alt="By Bagges produkt billede" />
                                        </div>
                                        <article className="fpBybaggesProdsSingleTextContainer">
                                            <div className="fpBybaggesProdsSingleNamePriceFlexContainer">
                                                <div className="fpBybaggesProdsSingleNamePriceContainer">
                                                    <h3 className="fpBybaggesProdsSingleName">{acf.bd_productsName}</h3>
                                                    <p className="fpBybaggesProdsSinglePrice">{acf.bd_productsPrice} kr.</p>
                                                </div>
                                                <div className="fpBybaggesProdsSingleTextWrapper">
                                                    <div className="fpBybaggesProdsSingleText" dangerouslySetInnerHTML={{__html: acf.bd_productsShortText}}></div>
                                                </div>
                                            </div>
                                            <div className="fpBybaggesProdsSingleBtnContainer">
                                                <Link className='fpBybaggesProdsSingleBtn' to={`/dreamsbybagges/${id}`}><span>Læs mere</span></Link>
                                            </div>
                                        </article>
                                    </div>
                                )
                            })}
                        </section>
                        <div className="fpBybaggesProdsBtnContainer">
                            <Link className='fpBybaggesProdsBtn' to='/dreamsbybagges'>{fp_bybaggesProdBtnText}</Link>
                        </div>
                    </section>
                )
            })}
        </section>
    )
}
