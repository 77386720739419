import React, { useState } from 'react';
import './cards.css';

export default function Cards({ cardsData }) {
    const [cards, setCards] = useState([cardsData]);
    
    return (    
        <section id="cards">
            {cards.map((data, index) => {
                const {fp_cards} = data;
                return (
                    <section key={index} className="cardsContainer">
                        {fp_cards.map((card, index) => {
                            const {fp_cardsHeading, fp_cardsText, fp_cardsIcon} = card;
                            return (
                                <section key={index} className="cardsSingleContainer">
                                    <div className="cardsSingleImageContainer">
                                        <img src={fp_cardsIcon} alt="Ikon" />
                                    </div>
                                    <h3 className="cardsSingleHeading">{fp_cardsHeading}</h3>
                                    <div className="cardsSingleText" dangerouslySetInnerHTML={{__html: fp_cardsText}}></div>
                                </section>
                            )
                        })}
                    </section>
                )
            })}
        </section>
    )
}
