import React, { useState } from 'react';

import CategoriesPage from '../components/baggeDesign/categories/Categories';

export default function Categories({ categoriesData }) {
    const [categories, setCategories] = useState(categoriesData);

    return (
        <>
            <CategoriesPage categoriesData={categories} />
        </>
    )
}
