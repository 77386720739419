import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './categories.css';

export default function Categories({ categoriesData }) {
    const [categories, setCategories] = useState(categoriesData);

    return (
        <section id="bdCat">
            <section className="bdCatContainer">
                {categories.map((data, index) => {
                    const { slug, acf, title } = data;
                    return (
                        <section key={index} className="bdCatSingleContainer">
                            <img src={acf.bd_generelImage} alt="Kategori billede" />
                            <div className="bdCatSingleWrapper">
                                <div className="bdCatSingleFlexCon">
                                    <h2 className="bdCatSingleName">{title.rendered}</h2>
                                    <Link className='bdCatSingleBtn' to={`/baggedesign/${slug}`}>Se album</Link>
                                </div>
                            </div>
                        </section>
                    )
                })}
            </section>
        </section>
    )
}
