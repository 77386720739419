import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import OrderForm from './OrderForm';
import './order.css';

export default function Order({ orderData, productsData }) {
    const [order, setOrder] = useState([orderData]);
    const [products, setProducts] = useState(productsData);
    const { productId } = useParams();

    let productOrder = [];

    products.map((product) => {
        if(product.id == productId) {
            productOrder.push(product);
        }
    })

    const baseURL = window.location.origin;
    const pathnameSplit = window.location.pathname.split('/');
    const productURL = `${baseURL}/${pathnameSplit[1]}/${pathnameSplit[2]}`;

    return (
        <section id="order">
            {productOrder.map((product) => {
                const {id , acf} = product;
                return (
                    <section key={id} className="orderContainer">
                        <h2 className="orderHeading">Reservering af {acf.bd_productsName}</h2>
                        <div className="orderWrapper">
                            <section className="orderFormContainer">
                                <OrderForm orderGeneral={order} productURL={productURL} productID={id} productStock={acf.bd_productStock} productName={acf.bd_productsName} productPrice={acf.bd_productsPrice} productBrand={acf.bd_productsBrand} isProductRing={acf.bd_productsIsRing} />
                            </section>

                            <section className="orderProductContainer">
                                <h3 className="orderProductHeding">Reservation af smykke</h3>
                                <div className="orderProductWrapper">
                                    <div className="orderProductImageContainer">
                                        <img src={acf.bd_productImages[0]} alt="Billede af smykke" />
                                    </div>
                                    <article className="orderProductTextContainer">
                                        <h3 className="orderProductTextName">{acf.bd_productsName}</h3>
                                        <p className="orderProductTextBrndName">{acf.bd_productsBrand === 'dreamsbybagges' ? 'Dreams By Bagges' : 'Bagge Design'}</p>
                                        <p className="orderProductTextQty">Stk: 1</p>
                                    </article>
                                    <div className="orderProductSinglePriceContainer">
                                        <p className="orderProductSinglePrice">{acf.bd_productsPrice} kr.</p>
                                    </div>
                                </div>

                                <div className="orderProductTotalContainer">
                                    <div className="orderProductTotalWrapper">
                                        <p className="orderProductTotalText">Subtotal</p>
                                        <p className="orderProductTotalPrice">{acf.bd_productsPrice} kr.</p>
                                    </div>
                                    <div className="orderProductTotalWrapper">
                                        <p className="orderProductTotalText">Total</p>
                                        <p className="orderProductTotalPrice">{acf.bd_productsPrice} kr.</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                )
            })}
        </section>
    )
}
