import React, { useState } from 'react';
import './about.css';

export default function About({ aboutData }) {
    const [about, setAbout] = useState([aboutData]);

    return (
        <section id="about">
            {about.map((data, index) => {
                const {fp_aboutHeading, fp_aboutShowVideo, fp_aboutImage, fp_aboutVideo, fp_aboutText} = data;

                const showImage = (src) => {
                    return (
                        <img src={src} alt="Bagge Design billede" className="aboutMedia" />
                    )
                }
                const showVideo = (src) => {
                    return (
                        <video className="aboutMedia" playsInline loop muted autoPlay>
                            <source src={src} />
                        </video>
                    )
                }

                return (
                    <section key={index} className="aboutContainer">
                        <section className="aboutTextContentContainer">
                            <article className='aboutTextWrapper'>
                                <h2 className="aboutHeading">{fp_aboutHeading}</h2>
                                <div className="aboutText" dangerouslySetInnerHTML={{__html: fp_aboutText}}></div>
                            </article>
                        </section>

                        <section className="aboutImageContainer">
                            {fp_aboutShowVideo ? showVideo(fp_aboutVideo) : showImage(fp_aboutImage)}
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
