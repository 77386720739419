import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import leftArrow from './assets/leftarrow.png';
import rightArrow from './assets/rightarrow.png';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1111 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1111, min: 850 },
        items: 1,
    },
    smallTablet: {
        breakpoint: { max: 850, min: 550 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 550, min: 0 },
        items: 1,
    }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group newsSlideBtnContainer">
            <div className={currentSlide === 0 ? 'disable newsLeftArrowWrapper' : 'newsLeftArrowWrapper'} onClick={() => previous()}><img src={leftArrow} alt="Left arrow ikon" /></div>
            <div className="newsRightArrowWrapper"  onClick={() => next()}><img src={rightArrow} alt="Right arrow ikon" /></div>
        </div>
    );
};

export default function NewsSlide({ slideData }) {
    const [slide, setSlide] = useState(slideData);

    return (
        <Carousel responsive={responsive} customButtonGroup={<ButtonGroup />}
            partialVisible={true}
            renderButtonGroupOutside={true}
            arrows={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all 500ms linear"
            transitionDuration={1000}
            containerClass="newscarousel-container"
            dotListClass="newscustom-dot-list-style"
            itemClass="newscarousel-item-padding-40-px"
        >
            {slide.map((singleSlide) => {
                const { id, acf } = singleSlide;

                return (
                    <section key={id} className="newsSingleSlideContainer">
                        <div className="newsSingleSlideImageContainer">
                            <img src={acf.bd_productImages[0]} alt="Billede af smykke" />
                        </div>
                        <article className="newsSingleSlideTextContainer">
                            <div className="newsSingleSlideNamePriceFlexContainer">
                                <div className="newsSingleSlideNamePriceContainer">
                                    <h3 className="newsSingleSlideName">{acf.bd_productsName}</h3>
                                    <p className="newsSingleSlidePrice">{acf.bd_productsPrice} kr.</p>
                                </div>
                                <div className="newsSingleSlideTextWrapper">
                                    <div className="newsSingleSlideText" dangerouslySetInnerHTML={{__html: acf.bd_productsShortText}}></div>
                                </div>
                            </div>
                            <div className="newsSingleSlideBtnContainer">
                                <Link className='newsSingleSlideBtn' to={`/baggedesign/${acf.bd_productCategory}/${id}`}><span>Læs mere</span></Link>
                            </div>
                        </article>
                    </section>
                )
            })}
        </Carousel>
    )
}
