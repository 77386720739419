import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './landingPage.css';

export default function LandingPage({landingpageData}) {
    const [landingpage, setLandingpage] = useState([landingpageData]);

    return (
        <section id="landingpage">
            {landingpage.map((data, index) => {
                const {fp_landingHeading, fp_landingSubheadingText, fp_landingShowVideo, fp_landingImage, fp_landingVideo, fp_landingBtnText} = data;

                const showImage = (src) => {
                    return (
                        <img src={src} alt="Baggrund" className="landingpageBgMedia" />
                    )
                }
                const showVideo = (src) => {
                    return (
                        <video className="landingpageBgMedia" playsInline loop muted autoPlay>
                            <source src={src} />
                        </video>
                    )
                }

                return (
                    <section key={index} className="landingpageContainer">
                        <div className="landingpageBgContainer">
                            {fp_landingShowVideo ? showVideo(fp_landingVideo) : showImage(fp_landingImage)}
                        </div>
                        <article className='landingpageTextContainer'>
                            <div className="landingpageTextWrapper">
                                <h1 className="landingpageHeading">{fp_landingHeading}</h1>
                                <div className="landingpageText" dangerouslySetInnerHTML={{__html: fp_landingSubheadingText}}></div>
                                <Link className='landingpageCta' to='/baggedesign'>{fp_landingBtnText}</Link>
                            </div>
                        </article>
                    </section>
                )
            })}
        </section>
    )
}
