import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

import { FaPhone } from "react-icons/fa6";
import { IoMdPin } from "react-icons/io";

export default function Footer({ footerData, contactData }) {
    const [footer, setFooter] = useState([footerData]);
    const [contact, setContact] = useState([contactData]);

    return (
        <section id="footer">
            {footer.map((data, index) => {
                const {footerLogo, footerShortText, footerCopyrightsText, footerFacebookLink} = data;
                return (
                    <section key={index} className="footerContainer">
                        <section className="footerWrapper">
                            <article className="footerItemContainer">
                                <div className="footerFlexWrapper">
                                    <img src={footerLogo} alt="Logo" />
                                    <div className="footerShortText" dangerouslySetInnerHTML={{__html: footerShortText}}></div>
                                </div>
                                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100054256461772&tabs=timeline&width=340&height=250&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=552483972844314" width="340" height="250"  scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </article>

                            {contact.map((data, index) => {
                                const { contactHorsensHeading, contactHorsensAddress, contactHorsensOpenHours, contactHedenstedHeading, contactHedenstedAddress, contactHedenstedOpenHours, contactHorsensNumber, contactHedenstedNumber} = data;
                                return (
                                    <div key={index} className="footerItemContainer">
                                        <div className="footerContactContainer">
                                            <h3 className="footerContactHeading">{contactHorsensHeading}</h3>
                                            <div className="footerContactAddressContainer">
                                                <IoMdPin />
                                                <p className="footerContactAddress">{contactHorsensAddress}</p>
                                            </div>
                                            <div className="footerContactPhoneContainer">
                                                <FaPhone />
                                                <p className="footerContactPhone">{contactHorsensNumber}</p>
                                            </div>
                                            <article className="footerContactOpenHoursContainer">
                                                {contactHorsensOpenHours.map((hours, index) => {
                                                    const {contactHorsensOpenHoursDay, contactHorsensOpenHoursTime} = hours;
                                                    return (
                                                        <div key={index} className="footerContactOpenHoursWrapper">
                                                            <p className="footerContactOpenHoursDayTime">{contactHorsensOpenHoursDay}</p>
                                                            <p className="footerContactOpenHoursDayTime">{contactHorsensOpenHoursTime}</p>
                                                        </div>
                                                    )
                                                })}
                                            </article>
                                        </div>

                                        <div className="footerContactContainer">
                                            <h3 className="footerContactHeading">{contactHedenstedHeading}</h3>
                                            <div className="footerContactAddressContainer">
                                                <IoMdPin />
                                                <p className="footerContactAddress">{contactHedenstedAddress}</p>
                                            </div>
                                            <div className="footerContactPhoneContainer">
                                                <FaPhone />
                                                <p className="footerContactPhone">{contactHedenstedNumber}</p>
                                            </div>
                                            <article className="footerContactOpenHoursContainer">
                                                {contactHedenstedOpenHours.map((hours, index) => {
                                                    const {contactHedenstedOpenHoursDay, contactHedenstedOpenHoursTime} = hours;
                                                    return (
                                                        <div key={index} className="footerContactOpenHoursWrapper">
                                                            <p className="footerContactOpenHoursDayTime">{contactHedenstedOpenHoursDay}</p>
                                                            <p className="footerContactOpenHoursDayTime">{contactHedenstedOpenHoursTime}</p>
                                                        </div>
                                                    )
                                                })}
                                            </article>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className="footerItemContainer">
                                <h3 className="footerMenuHeading">Menu</h3>
                                <ul className="footerMenuContainer">
                                    <li className='footerMenuItem'>
                                        <Link className='footerMenuLink' to='/'>Forside</Link>
                                    </li>
                                    <li className='footerMenuItem'>
                                        <Link className='footerMenuLink' to='/baggedesign'>Bagge Design</Link>
                                    </li>
                                    <li className='footerMenuItem'>
                                        <Link className='footerMenuLink' to='/dreamsbybagges'>Dreams By Bagges</Link>
                                    </li>
                                    <li className='footerMenuItem'>
                                        <Link className='footerMenuLink' to='kontakt'>Kontakt</Link>
                                    </li>
                                </ul>
                            </div>
                            
                        </section>
                        <article className='footerCopyrightContainer'>
                            <div className="footerCopyright" dangerouslySetInnerHTML={{__html: footerCopyrightsText}}></div>
                        </article>
                    </section>
                )
            })}
        </section>
    )
}
