import emailjs from '@emailjs/browser';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { useForm } from 'react-hook-form';
import { BsCheckLg } from 'react-icons/bs';
import { FaTruckMoving } from "react-icons/fa6";
import { MdError } from 'react-icons/md';

export default function OrderForm({ orderGeneral, productURL, productID, productStock, productName, productPrice, productBrand, isProductRing }) {
    const [orderGeneralData, setOrderGeneral] = useState(orderGeneral);
    const [pickup, setPickup] = useState({value: 'Horsens', label: 'Horsens'});
    const [productStockSize, setproductStockSize] = useState(parseInt(productStock));
    const formRef = useRef();
    const form = useForm();
    const { register, handleSubmit, formState, reset } = form;
    const { errors, isSubmitSuccessful } = formState;

    const [success, setSuccess] = useState(false);

    const options = [
        {value: 'Horsens', label: 'Horsens'},
        {value: 'Hedensted', label: 'Hedensted'},
    ]
    const handleChange = (selectedOption) => {
        setPickup(selectedOption);
    }

    console.log(productStockSize);


    const sendEmail = () => {
        const serviceKey = 'service_a1g59rn';
        const publicKey = '-RcJ-p5Sbuzu7nWZc';
        const templateKey = 'template_mxovjxp';
        const templateKeyConfirmation = 'template_zukg18b';
        emailjs.sendForm(
            serviceKey, 
            templateKey, 
            formRef.current, 
            publicKey)
            .then((result) => {
                // console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

            emailjs.sendForm(
                serviceKey, 
                templateKeyConfirmation,
                formRef.current, 
                publicKey)
                .then((result) => {
                    // console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
    };

    const updateStock = (productID, newStock) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic dGJuZGVzaWduOlRibmRlc2lnbjE=");
    
        let raw = JSON.stringify({
            "fields": {
                "bd_productStock": newStock
            }
        });
    
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
    
        fetch(`https://baggedesigncms.baggedesign.dk/wp-json/wp/v2/products/${productID}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    const getProductStock = async () => {
        const baseURL = 'https://baggedesigncms.baggedesign.dk/wp-json/wp/v2/';
        const productsURL = `${baseURL}products`;
        const fetchURLs = [productsURL];
		const [productsData] = await Promise.all(
		  	fetchURLs.map((url) => fetch(url).then((res) => res.json()))
		);
        let productStock = '';
        productsData.map((product) => {
            if(product.id == productID) {
                productStock = parseInt(product.acf.bd_productStock);
            }
        })
        if(productStock > 0) {
            sendEmail();
            updateStock(productID, productStock - 1);
            setproductStockSize(prev => prev - 1);
            setSuccess(true);
        } else {
            setproductStockSize(0);
        }
	}


    const onSubmit = () => {
        getProductStock();
    }

    useEffect(() => {
        if(isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset])

    const horsensAddress = () => {
        return (
            <>
                <input type="hidden" name='horsensAddressStreet' value='Hospitalsgade 9,' />
                <input type="hidden" name='horsensAddressZipcode' value='8700 Horsens' />
            </>
        )
    }

    const hedenstedAddress = () => {
        return (
            <>
                <input type="hidden" name='hedenstedAddressStreet' value='Vejlevej 8,' />
                <input type="hidden" name='hedenstedAddressZipcode' value='8722 Hedensted' />
            </>
        )
    }

    const ringSize = () => {
        return (
            <div className="orderFormItemGroupContainer">
                <div className="orderFormItemContainer">
                    <div className="orderFormLabelErrorMsgContainer">
                        <label htmlFor='ringSize'>Vælg ringstørrelse</label>
                        <p className='inputErrorMsg'>{errors.ringSize ? <MdError /> : <></>}{errors.ringSize?.message}</p>
                    </div>
                    <p className="orderFormRingText">NB forfindes i størrelse 50-60 i lige numre.</p>
                    <input type='text' id='ringSize' {...register('ringSize', {
                        pattern: {
                            value: /^(50|52|54|56|58|60)$/,
                            message: 'ikke gyldig ringstørrelse',
                        },
                        required: {
                            value: true,
                            message: 'Påkrævet',
                        }
                    })} />
                </div>
            </div>
        )
    }

    const ringSizeInput = () => {
        return (
            <input type="hidden" name='ringSizeText' value='Ringstørrelse: ' />
        )
    }

    const reserverbtn = () => {
        const btn = () => {
            return (
                <button className='orderFormBtn'>Reserver</button>
            )
        }
        const btnSoldOut = () => {
            return (
                <button className='orderFormBtn soldOut'>Udsolgt</button>
            )
        } 
        return (
            <div className="orderFormBtnContainer">
                {success && ( <p className="orderFormSuccesMsg"><BsCheckLg /> Din reservation er sendt</p> )}
                {productStockSize > 0 ? btn() : btnSoldOut()}
            </div>
        )
    }

    const stockStatus = () => {
        if(productStockSize > 4) {
            return 'Mere end 5 på lager';
        } else if(productStockSize < 5 && productStockSize > 0) {
            return 'Mindre end 5 på lager';
        } else if(productStockSize === 0) {
            return 'Udsolgt - Kontakt Bagge Design';
        }
    }

    return (
        <form className="orderForm" ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
            <input type="hidden" name='productName' value={productName} />
            <input type="hidden" name='productPrice' value={productPrice} />
            <input type="hidden" name='productBrand' value={productBrand} />
            <input type="hidden" name='productLink' value={productURL} />
            <input type="hidden" name='pickupPlace' value={`Bagges i ${pickup.value}`} />
            {pickup.value === 'Horsens' ? horsensAddress() : hedenstedAddress()}
            {isProductRing === 'true' ? ringSizeInput() : ''}
            <div className="orderFormItemGroupContainer">
                <div className="orderFormItemContainer">
                    <div className="orderFormLabelErrorMsgContainer">
                        <label htmlFor="firstname">Fornavn</label>
                        <p className="inputErrorMsg">{errors.firstName ? <MdError /> : <></>}{errors.firstName?.message}</p>
                    </div>
                    <input type='text' id='firstName' {...register('firstName', {
                        required: {
                            value: true,
                            message: 'Påkrævet',
                        }
                    })} />
                </div>
                <div className="orderFormItemContainer">
                    <div className="orderFormLabelErrorMsgContainer">
                        <label htmlFor="lastName">Efternavn</label>
                        <p className="inputErrorMsg">{errors.lastName ? <MdError /> : <></>}{errors.lastName?.message}</p>
                    </div>
                    <input type='text' id='lastName' {...register('lastName', {
                        required: {
                            value: true,
                            message: 'Påkrævet',
                        }
                    })} />
                </div>
            </div>
            
            <div className="orderFormItemGroupContainer">
                <div className="orderFormItemContainer">
                    <div className="orderFormLabelErrorMsgContainer">
                        <label htmlFor="email">E-mail</label>
                        <p className="inputErrorMsg">{errors.email ? <MdError /> : <></>}{errors.email?.message}</p>
                    </div>
                    <input type='email' id='email' {...register('email', {
                        pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Ikke godkendt email',
                        },
                        required: {
                        value: true,
                        message: 'Påkrævet',
                        }
                    })} />
                </div>
                
                <div className="orderFormItemContainer">
                    <div className="orderFormLabelErrorMsgContainer">
                        <label htmlFor='phoneNumber'>Telefon</label>
                        <p className='inputErrorMsg'>{errors.phoneNumber ? <MdError /> : <></>}{errors.phoneNumber?.message}</p>
                    </div>
                    <input type='text' id='phoneNumber' {...register('phoneNumber', {
                        pattern: {
                            value: /^[0-9]{8}$/,
                            message: 'ikke gyldigt nummer',
                        },
                        required: {
                            value: true,
                            message: 'Påkrævet',
                        }
                    })} />
                </div>
            </div>

            {isProductRing === 'true' ? ringSize() : ''}

            <div className="orderFormItemGroupContainer">
                <div className="orderFormItemContainer">
                    <div className="orderFormLabelErrorMsgContainer">
                        <label htmlFor='pickup'>Vælg afhentningssted</label>
                        <p className='inputErrorMsg'>{errors.pickup ? <MdError /> : <></>}{errors.pickup?.message}</p>
                    </div>
                    <Select id='pickup' options={options} onChange={handleChange} value={pickup} />
                </div>
            </div>


            


            {orderGeneralData.map((orderData, index) => {
                const {orderDeliveryHeading, orderDeliveryText, orderPolicyText} = orderData;
                return (
                    <section key={index}>
                        <div className="orderFormDeliveryContainer">
                            <div className="orderFormDeliveryIconContainer">
                                <FaTruckMoving />
                                <h3 className="orderFormDeliveryHeading">{orderDeliveryHeading}</h3>
                            </div>
                            <div className="orderFormDeliveryText" dangerouslySetInnerHTML={{__html: orderDeliveryText}}></div>
                        </div>

                        <div className="orderFormPolicyContainer">
                            <div className="orderFormPolicyText" dangerouslySetInnerHTML={{__html: orderPolicyText}}></div>
                        </div>
                    </section>
                )
            })}

            <div className="orderFormItemGroupContainer">
                <div className="orderFormItemContainer">
                    <p className="orderFormStockSize">{stockStatus()}</p>
                </div>
            </div>

            {reserverbtn()}
        </form>
    )
}
