import React, { useState } from 'react';
import Slide from './ReviewsSlide';
import './reviews.css';

export default function Reviews({ reviewsData }) {
    const [reviews, setReviews] = useState([reviewsData]);

    return (
        <section id="reviews">
            {reviews.map((data, index) => {
                const {fp_reviewsHeading, fp_reviewsSubHeading, fp_reviewsRepeater} = data;

                return (
                    <section key={index} className="reviewsContainer">
                        <div className="rewiewsWrapper">
                            <h2 className="reviewsHeading">{fp_reviewsHeading}</h2>
                            <div className="reviewsSubheading" dangerouslySetInnerHTML={{__html: fp_reviewsSubHeading}}></div>
                            <section className="reviewsSlideContainerWrapper">
                                <Slide slideData={fp_reviewsRepeater} />
                            </section>
                        </div>
                    </section>
                )
            })}
        </section>
    )
}
